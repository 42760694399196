<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form :model="searchForm" :labelCol="{ flex: '100px' }" layout="inline">
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="searchForm.orderId" placeholder="合同号" />
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="searchForm.vinNo" placeholder="车架号" />
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-range-picker v-model:value="searchForm.timeLimit" style="width:100%" format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD" :showTime="false" placeholder="录单时间" />
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-input-group compact>
            <a-input style="width: 50%" v-model:value="searchForm.startAddress">
              <template #prefix>始</template>
            </a-input>
            <a-input style="width: 50%" v-model:value="searchForm.endAddress">
              <template #prefix>终</template>
            </a-input>
          </a-input-group>
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ flex: 'auto' }">
          <a-select style="width:150px" v-model:value="searchForm.isInsurance" :options="options" @change="insuranceChange"
            placeholder="保单上传状态">
          </a-select>
        </a-form-item>
        <a-space>
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button @click="resetSearch">重置</a-button>
          <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
            <UpOutlined v-if="open" />
            <DownOutlined v-else />
          </a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarRight>
      <!-- <a-button type="primary"
                @click="onClick(record,1)">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button> -->
    </template>
    <a-table :columns="[sortColumn, ...columns]" :rowKey="(record, index) => { return index }" :data-source="listData"
      :pagination="pagination" :loading="loading" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>

      <template #operation="{ record }">
        <a @click="onClick(record, 1)">详情</a>
        <a-divider type="vertical" />
        <a @click="onClick(record, 2)" v-if="record.insurance === 1">查看保险</a>
        <a-divider type="vertical" />
      </template>
      <template #Status="{ record }">
        <span
          :class="[(record.vehicleTransStatus.value === 0) ? 'f1ab18' : '', (record.vehicleTransStatus.value === 1) ? 'd89f8' : '', (record.vehicleTransStatus.value === 2) ? 'cb32f' : '', (record.vehicleTransStatus.value === 3) ? 'ee5c23' : '', (record.vehicleTransStatus.value === 4) ? 'lv' : '', (record.vehicleTransStatus.value === 5) ? 'e3e3e3' : '']">
          {{ record.vehicleTransStatus.label }}</span>
      </template>
    </a-table>
  </suy-table>
  <a-drawer :title="title" placement="right" :closable="false" width="80%" v-model:visible="visible">
    <div class="insurance-drawer-div" v-if="title === '订单详情'">
      <a-row>
        <a-col :span="8"><span class="insurance-col-span">合同号：</span> {{ insuranceData.orderId }} </a-col>
        <a-col :span="8"><span class="insurance-col-span">起运日期：</span> {{ insuranceData.transportTime }} </a-col>
        <a-col :span="8"><span class="insurance-col-span">承运商：</span> {{ insuranceData.carrierName }}</a-col>
        <a-col :span="8"><span class="insurance-col-span">始发地：</span> {{ insuranceData.startAddress }}</a-col>
        <a-col :span="8"><span class="insurance-col-span">目的地：</span> {{ insuranceData.endAddress }}</a-col>
      </a-row>
      <!-- <div class="insurance-col-div-for"
           v-for="(item,index) in insuranceData.vehicles"
           :key="item.vinNo">
        <h1>车辆{{index+1}}</h1>
        <a-row justify="end">
          <a-col :span="6"><span class="insurance-col-span">车牌品牌:</span> {{item.brand}} </a-col>
          <a-col :span="6"><span class="insurance-col-span">车辆型号:</span> {{item.model}} </a-col>
          <a-col :span="6"><span class="insurance-col-span">车架号:</span> {{item.vinNo}}</a-col>
          <a-col :span="6"><span class="insurance-col-span">投保额度:</span> {{item.insurancePremium}}</a-col>
        </a-row>
      </div> -->
      <a-table :columns="vehicleColumns" :data-source="insuranceData.vehicles" :pagination="false"
        :rowKey="(record, index) => { return index }">
        <template #vehicleSort="{ index }">
          车辆 {{ index + 1 }}
        </template>
        <template #insuranceFee="{ record }">
          {{ record.insuranceFee }}万元
        </template>
        <template #footer>
          <a-row>
            <a-col :span="4" class=" footer-col-span"><span class="insurance-col-span">投保台数:</span>{{ insuranceData.num }}
            </a-col>
            <a-col :span="5" class=" footer-col-span"><span class="insurance-col-span">投保总金额:</span>
              {{ insuranceData.total }}</a-col>
          </a-row>
        </template>
      </a-table>

    </div>
    <div>
      <a-upload-dragger v-model:fileList="fileList" name="file" action="/*" v-if="title === '订单详情'"
        @change="handleChange">
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">
          <CloudFilled />
        </p>
        <p class="ant-upload-hint">
          点击上传，或将文件拖拽到此处
        </p>
      </a-upload-dragger>
      <iframe v-if="insuranceData.fileUrl != null && insuranceData.fileUrl.length > 1" :src="insuranceData.fileUrl"
        :style="iframeStyle"></iframe>
    </div>

  </a-drawer>
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
import { CloudFilled } from '@ant-design/icons-vue'
import { fileUpload as uploadPdf } from '@/api/marketing/modList'
import { page, detail, add } from '@/api/system/insuranceOutOne'
export default {
  components: {
    SuyTable,
    CloudFilled
  },
  setup () {
    const state = reactive({
      title: '订单详情',
      open: false,
      loading: false,
      visible: false,
      method: {
        authorization: ''
      },
      iframeStyle: {
        width: '100%',
        height: '100%'
      },
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        carNo: '',
        isInsurance: null,
        startAddress: '',
        endAddress: '',
        timeLimit: []
      },
      mirrorSearchForm: {},
      listData: [],
      insuranceData: {},
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '订单号',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '录单时间',
          dataIndex: 'orderTime',
          key: 'orderTime'
        },
        {
          title: '小车数量',
          dataIndex: 'truckNum',
          key: 'truckNum'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      vehicleColumns: [
        {
          title: '序号',
          dataIndex: 'startAddress',
          slots: { customRender: 'vehicleSort' }
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车辆类型',
          dataIndex: 'vehicleTypeName',
          key: 'truckNum'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },

        {
          title: '投保额度',
          dataIndex: 'insuranceFee',
          slots: { customRender: 'insuranceFee' }
        }

      ],
      options: [
        {
          label: '未上传',
          value: 1
        },
        {
          label: '已上传',
          value: 2
        }
      ],
      form: {
        paidAmt: 0,
        officeOrgId: '',
        vehicleId: ''
      },
      fileList: [],
      fileUid: '',
      orderId: ''
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetSearch = () =>{
      state.pagination.current = 1
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        orderId: state.searchForm.orderId,
        vinNo: state.searchForm.vinNo,
        isInsurance: state.searchForm.isInsurance,
        pushStartTime: state.searchForm.timeLimit[0],
        pushEndTime: state.searchForm.timeLimit[1],
        startAddress: state.searchForm.startAddress,
        endAddress: state.searchForm.endAddress
      })
      state.listData = records

      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(()=>{
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          state.title = '订单详情'
          state.visible = true
          getHeight()
          insuranceDetail(recode.id)

          break
        case 2:
          state.title = '查看保单'
          state.visible = true
          getHeight()
          insuranceDetail(recode.id)  
          break
        default:
          message.success('出现错误' + type)
      }
    }
    const insuranceChange = (e, v) => {
      state.searchForm.isInsurance = e
    }
    const insuranceDetail = (orderId) => {
      state.orderId = orderId
      detail(orderId).then(res => {
        if (res.code === 10000) {
          state.insuranceData = res.data
        }
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const handleChange = info => {
      state.fileList = []
      if (state.fileUid !== info.file.uid) {
        state.fileUid = info.file.uid
        const formData = new FormData()
        formData.append('file', info.fileList[0].originFileObj)
        uploadPdf(formData).then(res => {
          if (res.code === 10000) {
            add(state.orderId, res.data.id).then(data => {
              if (data.code === 10000) {
                message.info(data.msg)
                insuranceDetail(state.orderId)
              }
            })
          }
        })
      }
    }
    const getHeight = () => {
      state.iframeStyle.height = window.innerHeight - 64 - 68 + 'px'
    }
    return {
      ...toRefs(state),
      loadData,
      onSearch,
      handleChange,
      insuranceChange,
      insuranceDetail,
      handleTableChange,
      onClick
    }
  }
}
</script>
