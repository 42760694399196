import { getRequest, postBodyRequest } from '@/utils/axios'

// 太平洋保险-订单列表
export function page (params) {
  return postBodyRequest('/trans/carrier/system/insurance-sz/page', params)
}
// 查看太平洋订单信息
export function detail (params) {
  return getRequest('/trans/carrier/system/insurance-sz/' + params)
}
// 上传太平洋保单
export function add (params, fileId) {
  return postBodyRequest(`/trans/carrier/system/insurance-sz/${params}/${fileId}`, params)
}
